<template>
  <div>
    <Card>
      <Row>
        <Col span="2" style="text-align: left">
          <Button type="primary" ghost @click="createUser"><Icon type="ios-add" />添加用户</Button>
        </Col>
        <Col span="2" style="text-align: left">
          <Button type="error" ghost @click="deleteUser" v-if="isShowDelBtn"><Icon type="ios-trash-outline" />删除用户</Button>
        </Col>
      </Row>
    </Card>
    <div>
      <Table border ref="selection" :columns="columns" :data="data" @on-selection-change="selecte" @on-select-all="selectAll" @on-select-all-cancel="selectAllCancel"></Table>
    </div>
    <base-drawer :is-show="drawer.isShow" :title="drawer.title" @closeDrawer="closeDrawer" @submit="submit">
      <Row :gutter="32" v-if="isEdit">
        <Col span="12" style="text-align: center">
          <Avatar src="https://i.loli.net/2017/08/21/599a521472424.jpg" size="80"/>
          <div style="margin-top: 16px;"><a>修改 <Icon type="ios-create-outline" /></a></div>
        </Col>
<!--        <Col span="12">-->
<!--          <div><span style="font-weight: bold">用户 ID:</span> {{formData.id}}</div>-->
<!--          <div><span style="font-weight: bold">登录账号:</span> {{formData.account}}</div>-->
<!--          <div><span style="font-weight: bold">创建时间:</span> 2021-06-25 00:00:00</div>-->
<!--        </Col>-->
      </Row>
      <Form ref='userForm' :model="formData" :rules="ruleInline">
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="用户名" prop="username" label-position="top">
              <Input v-model="formData.username" placeholder="用户名" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="登录账号" prop="account" label-position="top">
              <Input v-model="formData.account" placeholder="登录账号" />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="登录密码" prop="password" label-position="top">
              <Input v-model="formData.password" :disabled="isEdit" type="password" placeholder="登录密码" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="邮箱" prop="email" label-position="top">
              <Input v-model="formData.email" placeholder="邮箱" />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="角色分配" prop="roles" label-position="top">
              <Select v-model="formData.roles" multiple placeholder="角色分配">
                <Option v-for="(roleObj, index) in drawer.roles" :value="roleObj.id" :key="index">{{ roleObj.role }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="状态" prop="enabled" label-position="top">
              <Select v-model.number="formData.enabled" placeholder="状态">
                <Option :value="0">禁用</Option>
                <Option :value="1">正常</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </base-drawer>
  </div>
</template>

<script>
import BaseDrawer from "@/components/base/baseDrawer";
import Notice from "@/components/baseNotice.js";
import {get, post, put, del} from "@/api/http.js";
let md5 = require('js-md5');
export default {
  name: "user",
  data () {
    return {
      isEdit: false,
      drawer:{
        isShow: false,
        title:'',
        roles: []
      },
      formData: {
        username: '',
        roles: [],
        account: '',
        password: '',
        email: '',
        enabled: 1
      },
      ruleInline: {
        username: [
          { required: true, message: 'Please fill in the user username', trigger: 'blur' }
        ],
        account: [
          { required: true, message: 'Please fill in the user account', trigger: 'blur' }
        ]
      },
      selectedUsers: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '用户名',
          key: 'username',
          render: (h, {row}) => {
            return h('div',[
              h("Avatar", {
                style: {
                  marginRight: '5px'
                },
                props: {
                  src: 'https://i.loli.net/2017/08/21/599a521472424.jpg'
                },
              }, row.username),
              h("span", row.username),
            ])
          }
        },
        {
          title: '登录账号',
          key: 'account'
        },
        {
          title: '角 色',
          key: 'roles',
          render: (h, {row}) => {
            let roles = row.roles.map(e => e.comment)
            return h('span', roles.join(","))
          }
        },
        {
          title: '邮 箱',
          key: 'email'
        },
        {
          title: '状 态',
          key: 'enabled',
          width: 100,
          render: (h, {row}) => {
            let statusColor = {
              "0": ['禁用', '#ed4014'],
              "1": ['正常', '#19be6b']
            }
            return h('Badge',{
              props: {
                color: statusColor[row.enabled][1],
                text:  statusColor[row.enabled][0]
              },
            });
          }
        },
        // {
        //   title: '注册时间',
        //   key: 'created_at'
        // },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.updateUser(params.index)
                  }
                }
              }, '编 辑')
            ]);
          }
        }
      ],
      data: []
    }
  },
  components: {
    BaseDrawer
  },
  methods: {
    getRole() {
      get("/api/v1/role", "")
          .then((resp)=>{
            this.drawer.roles = resp.data;
          })
          .catch(e => {
            return e
          })
    },
    getUser(){
      get("/api/v1/user", "").then((resp)=>{
        this.data = resp.data;
        this.getRole();
      }).catch(e => {
        return e
      })
    },
    createUser() {
      this.isEdit = false;
      this.drawer.title = "添加用户";
      this.drawer.isShow = true;
      this.resetForm();
    },
    deleteUser() {
      let userId = this.selectedUsers.map(e => e.id);
      del("/api/v1/user", {"id": userId})
        .then(()=>{
          this.getUser();
          Notice.success("删除操作", "删除成功")
        }).catch((e)=>{
          return e
        })
    },
    updateUser (index) {
      this.isEdit = true;
      this.drawer.title = "编辑用户";
      this.drawer.isShow = true;
      this.formData = this.$_.pickBy(this.$_.cloneDeep(this.data[index]), (value, key) => {
        return !this.$_.endsWith(key, "At")
      });

      this.formData.roles = this.formData.roles.map((e)=> e.id)
    },
    closeDrawer() {
      this.drawer.isShow = false;
    },
    submit() {
      this.$refs.userForm.validate((valid) => {
            if (valid) {
              this.drawer.isShow = false;
              let postData = this.$_.cloneDeep(this.formData);
              console.log(postData)
              postData.password = md5(postData.password)
              postData.roles = this.drawer.roles.filter((e) => this.$_.includes(this.formData.roles, e.id))
              console.log(postData)
              if (this.isEdit) {
                put("/api/v1/user", postData)
                    .then(()=>{
                      this.getUser();
                      Notice.success("更新操作", "更新成功")
                    }).catch((e)=>{
                      return e
                    })
              } else {
                post("/api/v1/user", postData)
                    .then(()=>{
                      this.getUser();
                      Notice.success("提交操作", "添加成功")
                    }).catch((e)=>{
                  return e
                })
              }
            }
      })
    },
    selectAll(selection) {
      this.selectedUsers = selection;
    },
    selectAllCancel(selection) {
      this.selectedUsers = selection;
    },
    selecte(selection) {
      this.selectedUsers = selection;
    },
    resetForm() {
      this.formData = {
        username: '',
        roles: [],
        account: '',
        email: '',
        enabled: 1
      }
    }
  },
  computed: {
    isShowDelBtn() {
      return this.selectedUsers.length > 0;
    }
  },
  created() {
    this.getUser();
  }
}
</script>

<style scoped>
</style>